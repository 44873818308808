.side-menu {
  position: fixed;
  left: 0;
  top: 80px;
  display: grid;
  width: 250px;
  grid-template-columns: 100%;
  background-color: #fff;
  color: #263351;
  height: calc(100vh - 80px);
  overflow-y: auto;
  z-index: 500;
  transition: transform ease-in-out 0.2s;
  box-shadow: 2px 0px 3px -1px rgb(0 0 0 / 10%);

  ::-webkit-scrollbar {
    width: 0;
    height: 2px;
  }
}

.side-menu--hidden {
  transform: translateX(-225px);
}

@media screen and (max-width: 959px) {
  .side-menu {
    display: none;
  }
}
