// COLORS
$color-primary: #007bed;
$color-primary-light: #2299ff;
$color-primary-dark: #263351;

$color-secondary: #f50057;
$color-secondary-light: #f13678;

$color-orange: #ff9100;
$color-ambar: #ffb400;

$color-tertiary: #55c57a;
$color-tertiary-light: #7ed56f;
$color-tertiary-dark: #00c843;

$color-fourth: #0059ff;
$color-fourth-light: #2299ff;

$color-error: rgb(206, 25, 25);
$color-error-light: rgb(255, 56, 56);
$color-text-error: rgb(209, 80, 80);
$color-text-error-light: rgb(255, 118, 118);

$color-data-1: #05445e;
$color-data-2: #189ab4;
$color-data-3: #75e6da;
$color-data-4: #d4f1f4;
$color-data-5: #00293b;
$color-data-6: #3c777e;

// COLORS
$color-grey-dark-1: #111;
$color-grey-dark-2: #222;
$color-grey-dark-3: #333;
$color-grey-dark-4: #666;
$color-grey-dark-5: #777;
$color-grey-dark-6: #444;

// $color-grey-dark-1: #212121;
// $color-grey-dark-2: #333;
// $color-grey-dark-3: #444;
// $color-grey-dark-4: #777;
// $color-grey-dark-5: #888;
// $color-grey-dark-6: #555;

$color-grey-light-1: #fff;
$color-grey-light-2: #fff;
$color-grey-light-3: #f7f7f7;
$color-grey-light-4: #aaa;
$color-grey-light-5: #999;

$color-white: #fff;
$color-black: #000;

// TYPOGRAPHY
$font-primary: "Noto Sans", sans-serif;
$font-title: "Fjalla One", sans-serif;
$default-font-size: 1.6rem;
$default-line-height: 1.6;

// BORDER
$default-border-radius: 5px;

// BOX SHADOW
$default-box-shadow: 0px 1px 5px -1px rgb(0 0 0 / 25%);
