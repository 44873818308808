@import "../../styles/abstracts/animations";
@import "../../styles/abstracts/mixins";
@import "../../styles/abstracts/variables";

.financing-warning {
  position: relative;
  padding: 1.5rem 1rem;
  margin: 1rem 1.8rem 0;
  background-color: $color-error;
  color: #fff;
  border-radius: $default-border-radius;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1.25rem;
  justify-items: center;

  &--orange {
    background-color: $color-ambar;
    color: #000;
  }

  &--red {
    background-color: $color-error;
    color: #fff;
  }

  &--cyan {
    background-color: $color-data-2;
    color: #fff;
  }

  &__txt {
    margin: 0;
  }

  &__cta {
    padding: 0.55rem 0.5rem;
    background-color: #fff;
    color: $color-error;
    border-radius: $default-border-radius;
    text-transform: uppercase;
    cursor: pointer;
    font-family: $font-primary;
    font-weight: 700;
    transition: all 0.2s;

    &:hover,
    &:active {
      background-color: rgba(#fff, 0.97);
    }

    &--red {
      color: $color-error;
    }

    &--orange {
      background-color: $color-data-1;
      color: #fff;

      &:hover,
      &:active {
        background-color: rgba($color-data-1, 0.95);
      }
    }

    &--cyan {
      color: $color-data-2;
    }
  }
}
