.omnichat {
  position: relative;
  display: flex;
  width: 100%;
  height: calc(90vh - 172px);

  &__left-container {
    display: flex;
    flex-direction: column;
    width: calc(100% / 3);
    height: 100%;

    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1rem 1rem 1rem 1.5rem;
      background-color: #263351;

      &__title {
        color: #fff;
      }

      &__actions {
        display: flex;
        
        svg {
          fill: #fff;
        }
      }
    }

    &__search {
      display: flex;
      align-items: center;
      padding: .6rem .6rem .6rem 1rem;
      background-color: #d1d1d1;
      gap: 10px;

      &__field {
        display: flex;
        align-items: center;
        flex: 1;
        gap: 10px;
        padding: .4rem 1rem;
        border: 1px solid #c2c2c2;
        border-radius: 10px;
        background-color: #dedede;

        &__preffix {
          display: inline-flex;

          svg {
            fill: #595959;
          }
        }

        &__input {
          width: 100%;
          height: 100%;
        }
      }

      &__right {
        display: flex;
      }
    }

    &__contact-list {
      flex: 1;
      overflow: auto;
      background-color: #eee;

      &__item {
        position: relative;
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: 0rem 1.5rem;
        transition: all;
        transition-duration: 200ms;

        &:hover {
          background-color: #dcdcdc;
        }

        &.active {
          background-color: #d0e1ff;
          border-left: 5px solid #263351;
          
          // &:after {
          //   content: '';
          //   position: absolute;
          //   left: 0;
          //   width: 5px;
          //   height: 100%;
          //   background-color: #263351;
          // }
        }

        &__avatar {
          display: flex;

          img {
            width: 3rem;
            aspect-ratio: 1 / 1;
            border-radius: 99999px;
          }
        }

        &__details {
          flex: 1;
          padding: 1.5rem 0rem;
          margin-left: 1rem;
          border-bottom: 1px solid #dedede;

          &__over {
            display: grid;
            width: 100%;

            p {
              opacity: .6;
              white-space: nowrap;
              text-transform: uppercase;
              text-overflow: ellipsis;
              overflow: hidden;
            }
          }

          &__header {
            display: flex;
            align-items: baseline;
            justify-content: space-between;
            
            span {
              font-size: .75rem;
              line-height: 1;
              opacity: .6;
            }
          }

          &__resume {
            display: grid;
            width: 100%;

            &__info {
              display: inline-flex;
              align-items: center;
              gap: 2px;

              svg {
                fill: #505050;
                font-size: 1.2rem;
              }
            }

            p {
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }
          }
        }
      }
    }
  }

  &__right-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-width: 30%;
    height: 100%;
    background-color: #dedede;

    &__nochat {
      flex: 1;
      overflow: auto;

      &__note {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;

        &__icon {
          svg {
            font-size: 4rem;
          }
        }

        &__title {
          
        }

        &__subtitle {
          
        }
      }
    }

    &__header {
      display: flex;
      align-items: center;
      padding: 1rem 1rem 1rem 1.5rem;
      background-color: #eee;

      &__back {
        display: flex;
        margin-right: 1rem;
      }

      &__overview {
        display: flex;
        align-items: center;
        cursor: pointer;
        transition: all;
        transition-duration: 200ms;

        &__avatar {
          display: flex;

          img {
            width: 3rem;
            aspect-ratio: 1 / 1;
            border-radius: 99999px;
          }
        }

        &__details {
          flex: 1;
          margin-left: 1rem;

          &__chat-name {
            font-weight: 700;
          }
        }
      }

      &__actions {
        display: flex;
        margin-left: auto;
      }
    }

    &__messages {
      flex: 1;
      overflow: auto;
      scroll-padding: 20px;

      &__container {
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding: 1rem 1rem;

        @media screen and (min-width: 1024px) {
          padding: 1rem 4rem;
        }

        &__intersection {
          // position: absolute;
          // top: 0;
          // left: 0;
          display: inline-flex;
          width: 100%;
          height: auto;
          min-height: 10px;
          margin-top: -1rem;
          // background: #0000000a;
          border-bottom-left-radius: 9999px;
          border-bottom-right-radius: 9999px;

          &__loader {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            padding: 1rem;
          }
        }

        &__row {
          display: flex;

          &:last-child {
            overflow-anchor: auto;
          }

          &.date {
            margin: 0 auto;
            position: sticky;
            top: 15px;
            z-index: 1;
          }

          &__date {
            padding: .4rem .8rem;
            border-radius: 20px;
            background-color: #263351;
            color: #ffffffc2;
          }

          &.myself {
            justify-content: flex-end;
          }

          &__card {
            position: relative;
            padding: .5rem 1rem;
            background-color: #fdfdfd;
            border-radius: 6px;

            &.myself::after {
              content: "";
              border-width: 6px;
              border-style: solid;
              border-color: #adcaff;
              border-bottom-color: transparent;
              border-left-color: transparent;
              border-radius: 1px;
              position: absolute;
              right: -5px;
              top: 10px;
              transform: rotate(45deg);
            }

            &:not(.myself)::after {
              content: "";
              border-width: 6px;
              border-style: solid;
              border-color: #fdfdfd;
              border-top-color: transparent;
              border-right-color: transparent;
              border-radius: 1px;
              position: absolute;
              left: -5px;
              top: 10px;
              transform: rotate(45deg);
            }

            &.myself {
              background-color: #adcaff;
            }

            &__seller-name {
              color: #007bed;
            }

            &__info {
              display: flex;
              justify-content: flex-end;
              margin-top: 4px;

              span {
                font-size: .7rem;
                line-height: 1;
                opacity: .6;
              }
            }
          }

          &__card-image {
            position: relative;
            display: inline-flex;
            flex-direction: column;
            padding: 4px;
            background-color: #fdfdfd;
            border-radius: 6px;
            width: 240px;
            max-height: 360px;
            aspect-ratio: 9 / 13;

            &.myself {
              background-color: #adcaff;

              &::after {
                content: "";
                border-width: 6px;
                border-style: solid;
                border-color: #adcaff;
                border-bottom-color: transparent;
                border-left-color: transparent;
                border-radius: 1px;
                position: absolute;
                right: -5px;
                top: 10px;
                transform: rotate(45deg);
              }
            }

            &:not(.myself)::after {
              content: "";
              border-width: 6px;
              border-style: solid;
              border-color: #fdfdfd;
              border-top-color: transparent;
              border-right-color: transparent;
              border-radius: 1px;
              position: absolute;
              left: -5px;
              top: 10px;
              transform: rotate(45deg);
            }

            &__seller-name {
              color: #007bed;
            }

            img {
              width: 100%;
              height: 100%;
              object-fit: cover; 
              border-radius: 4px;
              cursor: pointer;
            }

            &__info {
              position: absolute;
              bottom: 7px;
              right: 8px;
              display: flex;
              color: #fff;

              span {
                font-size: .7rem;
                line-height: 1;
                opacity: .8;
              }
            }
          }

          &__card-audio {
            position: relative;
            padding: .5rem 1rem;
            padding-left: 0.5rem;
            background-color: #fdfdfd;
            border-radius: 6px;

            &.myself {
              background-color: #adcaff;

              &::after {
                content: "";
                border-width: 6px;
                border-style: solid;
                border-color: #adcaff;
                border-bottom-color: transparent;
                border-left-color: transparent;
                border-radius: 1px;
                position: absolute;
                right: -5px;
                top: 10px;
                transform: rotate(45deg);
              }
            }

            &:not(.myself)::after {
              content: "";
              border-width: 6px;
              border-style: solid;
              border-color: #fdfdfd;
              border-top-color: transparent;
              border-right-color: transparent;
              border-radius: 1px;
              position: absolute;
              left: -5px;
              top: 10px;
              transform: rotate(45deg);
            }

            &__seller-name {
              color: #007bed;
            }

            img {
              width: 100%;
              height: 100%;
              object-fit: cover; 
              border-radius: 4px;
            }

            &__info {
              display: flex;
              justify-content: flex-end;
              margin-top: 4px;

              span {
                font-size: .7rem;
                line-height: 1;
                opacity: .6;
              }
            }
          }
        }
      }
    }

    &__footer {
      display: flex;
      align-items: center;
      padding: 1rem;
      background-color: #d1d1d1;
      gap: 10px;

      &__left {
        display: flex;
      }

      &__field {
        flex: 1;

        &__input {
          width: 100%;
          height: 100%;
          padding: .8rem 1rem;
          border: 1px solid #c2c2c2;
          border-radius: 10px;
          background-color: #dedede;
        }
      }

      &__right {
        display: flex;
      }
    }
  }

  &__search-container {
    display: flex;
    flex-direction: column;
    width: 25%;
    height: 100%;

    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1rem 1rem 1rem 1.5rem;
      background-color: #263351;

      &__title {
        color: #fff;
      }

      &__actions {
        display: flex;
        
        svg {
          fill: #fff;
        }
      }
    }

    &__input {
      display: flex;
      align-items: center;
      padding: .6rem .6rem .6rem 1rem;
      background-color: #d1d1d1;
      gap: 10px;

      &__field {
        display: flex;
        align-items: center;
        flex: 1;
        gap: 10px;
        padding: .4rem 1rem;
        border: 1px solid #c2c2c2;
        border-radius: 10px;
        background-color: #dedede;

        &__preffix {
          display: inline-flex;

          svg {
            fill: #595959;
          }
        }

        &__input {
          width: 100%;
          height: 100%;
        }
      }

      &__right {
        display: flex;
      }
    }

    &__message-list {
      flex: 1;
      overflow: auto;
      background-color: #eee;

      &__item {
        position: relative;
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: 0rem 1.5rem;
        transition: all;
        transition-duration: 200ms;

        &:not(:last-child) {
          border-bottom: 1px solid #dedede;
        }

        &:hover {
          background-color: #dcdcdc;
        }

        &__details {
          flex: 1;
          padding: 1.5rem 0rem;

          &__header {
            display: flex;
            align-items: baseline;
            justify-content: space-between;
            
            span {
              font-size: .75rem;
              line-height: 1;
              opacity: .6;
            }
          }

          &__resume {
            display: grid;
            width: 100%;

            p {
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }
          }
        }
      }
    }
  }
}